import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';


const Title = ({title, description}) => {
    return (
        <div className={'row'}>
            <div className={'col-lg-12'}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

const SubTitle = ({title, description}) => {
    return (
        <div className={'row'}>
            <div className={'col-lg-12'}>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
        </div>
    )
}

const Label = ({children}) => {
    return (
        <div className={'row'}>
            <div className={'col-lg-12'}>
                <div className={'pro_information_label'}>{children}</div>
            </div>
        </div>
    )
}

const Separator = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="separator"></div>
            </div>
        </div>
    )
}

const Select = ({value, options, onChange}) => {

    const [selected, setSelected] = useState(value)

    useEffect(() => {
        setSelected(value)
    }, [value])

    const getOptions = () => {
        return options.map((option, index) => {
            if(option.value == selected){
                return (
                    <option selected value={option.value}>{option.label}</option>
                ) 
            }
            return (
                <option value={option.value}>{option.label}</option>
            )
        })
    }
    return (
        <div className={'pro_information_category'}>
            <select className={'form-control'} onChange={(event) => onChange(event.target.value)}>
                {getOptions()}
            </select>
        </div>
    )
}

const MultiCheckbox = ({values, options, handleCheck}) => {
    
    const getOptions = () => {
        return options.map((option, index) => {
            const valuesIndex = _.findIndex(values, ['id', option.value])
            const selected = valuesIndex >= 0 ? true : false
            return (
                <div className={'col-lg-6'}>
                    <div className={'pro_information_skills__item '}>
                        <div className={selected ? 'pro_information_skills__checkbox checked' : 'pro_information_skills__checkbox'} onClick={() => handleCheck(option.value)}>
                            {selected ? <i class="fa-solid fa-check"></i> : ''}
                        </div>
                        {option.label}
                    </div>
                </div>
            )
        })
    }
    return (
        <div className={'pro_information_skills'}>
            <div className={'row'}>
                {getOptions()}
            </div>
        </div>
    )

}

const Expertise = () => {

    const [categories, setCategories] = useState([])
    const [skills, setSkills] = useState([])
    const [user, setUser] = useState({
        'category': null,
        'skills': []
    })

    const fetchExpertise = () => {
        axios.get('/pro/information/expertise.json', {}).then((res) => {
            const {data} = res
            setCategories(data.categories) 
            setSkills(data.skills)
            setUser(data.user)
        }).catch((e) => {
        })
    }

    useEffect(() => {
        fetchExpertise()
    }, [])

    const handleCategory = (value) => {
        axios.post('/pro/information/category', {category_id: value}).then((res) => {
            const {data} = res
            setUser(data)
        }).catch((e) => {
        })
    }

    const handleSkill = (value) => {
        axios.post('/pro/information/skill', {skill_id: value}).then((res) => {
            const {data} = res
            setUser(data)
        }).catch((e) => {
        })
    }

    const getCategoryOptions = () => {
        return categories.map((category, index) => {
            return {
                value: category.id,
                label: category.name
            }
        })
    }

    const getSkillsOptions = () => {
        return skills.map((skill, index) => {
            return {
                value: skill.id,
                label: skill.name
            }
        })
    }

    return (
        <>
            <Title title={'Expertise'} description={'Les compétences que vous fournissez seront affichées publiquement sur votre profil public et auront un impact direct sur la performance de réservation. Ces descriptions aident votre interlocuteur à déterminer si votre expertise correspond à son attente.'} />

            <div className="row mt-18">
                <div className="col-lg-6">
                    <Label>Domaine d'expertise</Label>
                    <Select value={_.get(user, 'category.id', null)} options={getCategoryOptions()} onChange={(value) => handleCategory(value)}/>
                </div>
            </div>

            <Label>Compétences</Label>
            <MultiCheckbox values={_.get(user, 'skills', [])} options={getSkillsOptions()} handleCheck={(value) => handleSkill(value)}/>
            <Separator />
        </>
    )

}

const Input = ({index, id, content, handleUpdate, handleDelete}) => {
    const [value, setValue] = useState(content)
    useEffect(() => {
        setValue(content)
    }, [content])
    return (
        <div className={'pro_information_ability'}>
            <input className={'form-control'} value={value} onChange={(event) => setValue(event.target.value)} onBlur={() => handleUpdate({id, content: value})} />
        </div>
    )
}

const Ability = () => {

    const [abilities, setAbilities] = useState([])

    useEffect(() => {
        axios.get('/pro/information/abilities', {}).then((res) => {
            const {data} = res
            setAbilities(data)
         }).catch((e) => {
         })
    }, [])

    const getAbilities = () => {
        return _.orderBy(abilities, ['id'], ['asc']).map((ability, index) => {
            return (
                <Input {...ability} index={index} handleUpdate={handleUpdate} handleDelete={handleDelete} />
            )
        })
    }

    const handleAdd = () => {
        axios.post('/pro/information/abilities/add', {}).then((res) => {
            const {data} = res
            const abilitiesClone = [...abilities]
            abilitiesClone.push(data)
            setAbilities(abilitiesClone)
         }).catch((e) => {
         })
    }

    const handleUpdate = ({id, content}) => {
        const abilityIndex = _.findIndex(abilities, ['id', id])
        if(abilityIndex >= 0){
            axios.post('/pro/information/abilities/'+id+'/update', {content}).then((res) => {
                const {data} = res
                const abilitiesClone = [...abilities]
                abilitiesClone[abilityIndex] = data
                setAbilities(abilitiesClone)
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const handleDelete = (id) => {
        const abilityIndex = _.findIndex(abilities, ['id', id])
        if(abilityIndex >= 0){
            axios.post('/pro/information/abilities/'+id+'/delete', {}).then((res) => {
                const {data} = res;
                let abilitiesClone = [...abilities]
                abilitiesClone.splice(abilityIndex, 1)
                setAbilities(abilitiesClone)
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const getButton = () => {
        return (
            <button className={'btn btn-primary'} onClick={handleAdd}>Ajouter</button>
        )
    }

    return (
        <>
            <SubTitle title={'Comment allez-vous aider votre interlocuteur ?'} description={'Veuillez fournir une liste d\'au moins quatre de vos compétences qui vous permettent de résoudre les problèmes spécifiques de votre interlocuteur.'} />
            {getAbilities()}
            {getButton()}
            <Separator />
        </>
    )
}

const Textarea = ({defaultValue, handleUpdate}) => {
    const [value, setValue] = useState(defaultValue)
    useEffect(() => {
        setValue(defaultValue)
        console.log('uodate')
    }, [defaultValue])
    return (
        <textarea className={'form-control'} onChange={(e) => setValue(e.target.value)} onBlur={() => handleUpdate(value)} value={value}></textarea>
    )
}

const About = () => {

    const [user, setUser] = useState({
        'description': ''
    })

    const fetchExpertise = () => {
        axios.get('/pro/information/about.json', {}).then((res) => {
            const {data} = res
            setUser(data)
        }).catch((e) => {
        })
    }

    useEffect(() => {
        fetchExpertise()
    }, [])

    const handleUpdate = ({description}) => {
        axios.post('/pro/information/about', {description}).then((res) => {
        }).catch((e) => {
        })
    }

    return (
        <div className={'pro_information_about'}>
            <SubTitle title={'Vous en quelques mots'} description={'Vous êtes un expert dans votre domaine, et les visiteurs aimeront forcément en savoir un peu plus sur vous ! Présentez-vous en quelques mots et partagez avec nous votre expérience et ce qui vous passionne dans votre domaine d\'expertise.'} />
            <Textarea defaultValue={user.description} handleUpdate={(value) => handleUpdate({description: value})} />
            <Separator />
        </div>
    )
}

const ProInformations = ({}) => {
    return (
        <div className={'container-fluid'}>
            <Expertise />
            <Ability />
            <About />
        </div>
    )
}

class ProInformationsElement extends HTMLElement {
   connectedCallback() {
      render(
         <ProInformations />, this
      );
   }

   disconnectedCallback() {
      unmountComponentAtNode(this);
   }
}

customElements.define('pro-informations-element', ProInformationsElement);
